import React from "react";
import styled from "styled-components";
import { AiOutlineUser } from "react-icons/ai";

const TopNav = ({ background, title, sub }) => {
  return (
    <>
      <TopNavStyles background={background}>
        <div id="gr">
          <div className="left">
            <h3>{title}</h3>
            <p>{sub}</p>
          </div>
          <div className="right">
            <div className="u_name">
              <p>Welcome Admin</p>
              <p>Becky Taiwo</p>
            </div>
            <AiOutlineUser />
          </div>
        </div>
      </TopNavStyles>
    </>
  );
};

export default TopNav;
const TopNavStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => `var(${background})`};
  position: sticky;
  height: 93px;
  top: 0;
  right: 0;
  z-index: 2000;

  #gr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    color: var(--white-color);
  }

  .left {
    p {
      font-size: 0.9rem;
      opacity: 0.8;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    .u_name {
      p {
        font-size: 0.95rem;

        &:nth-child(2) {
          font-weight: 600;
        }
      }
    }

    svg {
      font-size: 2rem;
      margin-left: 1rem;
      font-weight: bold;
    }
  }
`;
