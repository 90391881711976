const AllLinks = [
  {
    label: "TaxPayer Registration",
    subLinks: [
      {
        label: "Overview",
        route: "/dashboard/taxpayer",
      },
      {
        label: "Individual TaxPayer",
        route: "/dashboard/taxpayer/individual",
      },
      {
        label: "Business TaxPayer",
        route: "/dashboard/taxpayer/business",
      },
    ],
  },
  {
    label: "Tax Management",
    route: "/tax_management",
    subLinks: [
      {
        label: "Overview",
        route: "/dashboard/tax_management",
      },
      {
        label: "Personal Income Tax",
        route: "",
      },
      {
        label: "Pay-As-You-Earn(PAYE)",
        route: "/dashboard/tax_management/paye",
      },
      {
        label: "Direct Taxation",
        route: "",
      },
      {
        label: "Withholding Tax",
        route: "",
      },
      {
        label: "Capital Cains Task",
        route: "",
      },
      {
        label: "Property Tax",
        route: "",
      },
    ],
  },
  {
    label: "Taxes on Assets",
    route: "/taxes_assets",
  },
  {
    label: "NDA Collections",
    route: "/nda_collections",
  },
  {
    label: "Assessment",
    route: "/assessment",
  },
  {
    label: "Reporting",
    route: "/reporting",
  },
  {
    label: "Profiling",
    route: "/profiling",
  },
];

export default AllLinks;
