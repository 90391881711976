export const payeTable = [
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
  {
    tin: "2342394230923",
    business_name: "Softpattern",
    date_of_establishment: "12/21/1999",
    contact_person: "King Luta",
  },
];

export const taxableIncomeData = [
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
  {
    tin: "2323232323",
    employee_name: "Dani Reptor",
    gross_income: 34344343,
    pay_e: 866868,
    taxable_income: 4342323,
  },
];

