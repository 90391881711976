import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import GlobalStyles from "./styles/GlobalStyles";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard";
import TaskPayer from "./pages/TaskPayer";
import Home from "./pages/Home";
import IndividualPayer from "./pages/TaskPayer/Individual";
import BusinessPayer from "./pages/TaskPayer/Business";
import TaxManagement from "./pages/TaxManagement/Index";
import Paye from "./pages/TaxManagement/Paye";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <ChakraProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="home" element={<Home />} />
            <Route path="taxpayer" element={<TaskPayer />}>
              <Route path="individual" element={<IndividualPayer />} />
              <Route path="business" element={<BusinessPayer />} />
            </Route>
            <Route path="tax_management" element={<TaxManagement />}>
              <Route path="paye" element={<Paye />} />
            </Route>
          </Route>
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
