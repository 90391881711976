import star from "./logo.svg";
import logo from "./logo mark logo.svg";
import login_pattern from "./login pattern.svg";
import login_svg from "./login left svg.svg";

const images = {
  star,
  logo,
  login_pattern,
  login_svg,
};

export default images;
