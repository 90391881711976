import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Axios from "../../lib/Axios";
import Sidebar from "../../parts/Dashboard/Sidebar";

const Dashboard = () => {
  const navigator = useNavigate();
  const token = localStorage.getItem("irm_access");

  // check for token
  useEffect(() => {
    if (token) {
      // get user details
      Axios.get("/user/api/v1/profile/")
        .then((res) => console.log(res))
        .catch((err) => console.error(err));
    } else {
      navigator("/");
    }
    // eslint-disable-next-line
  }, [token, navigator]);

  return (
    <>
      <DashboardStyles>
        <Sidebar />
        <div className="children">
          <Outlet />
        </div>
      </DashboardStyles>
    </>
  );
};

export default Dashboard;
const DashboardStyles = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  .children {
    width: 80%;
    margin-left: 20%;
    padding-left: 1.8rem;
    background: var(--white-shade);
  }
`;
