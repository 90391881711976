import React from "react";
import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";
import { OutlinedBtn } from "../../components/Button/Button";
import TextInput from "../../components/Input/Input";

const InputManually = () => {
  return (
    <>
      <InputManuallyStyles>
        <div className="sec">
          <div className="head">
            <h3>Employee Basic Details</h3>
          </div>
          <div className="main">
            <div className="inn">
              <TextInput className="full" label="Employee Name" />
              <TextInput className="short" label="TIN" />
              <TextInput
                className="short"
                type="date"
                label="Employment Year"
              />
              <TextInput className="short" label="Position (Optional)" />
            </div>
          </div>
        </div>
        <div className="sec">
          <div className="head">
            <h3>Employee Income Package (Monthly)</h3>
          </div>
          <div className="main">
            <div className="inn">
              <TextInput className="short" label="Basic (N)" />
              <TextInput className="short" label="Housing (N)" />
              <TextInput className="short" label="Transport (N)" />
              <TextInput className="short" label="Other Allowances (N)" />
              <OutlinedBtn className="short" icon={<IoMdAdd />} text="Add Employee" />
            </div>
          </div>
        </div>
      </InputManuallyStyles>
    </>
  );
};

export default InputManually;
const InputManuallyStyles = styled.div`
  width: 100%;
  margin-top: 1.5rem;

  .head {
    padding: 0.9rem;
    background: var(--white-color);
    font-size: 0.85rem;
    font-weight: bold;
    color: var(--table-head);
  }

  .main {
    padding: 0.9rem;
    background: var(--card_bg);
  }

  .inn {
    width: 84%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .short {
    width: 150px;
  }

  .full {
    width: 300px;
  }
`;
