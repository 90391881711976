import React from "react";
import styled from "styled-components";
import { CgArrowsExpandLeft } from "react-icons/cg";

const CompanyTag = () => {
  return (
    <>
      <CompanyTagStyles>
        <p>TIN: 2832189321123</p>
        <h4>
          SoftPattern Technologies <CgArrowsExpandLeft />
        </h4>
        <p>Register Company</p>
      </CompanyTagStyles>
    </>
  );
};

export default CompanyTag;
const CompanyTagStyles = styled.div`
  width: 300px;
  padding: 10px 0;

  p {
    font-size: 0.87rem;
    font-weight: 500;
  }

  h4 {
    display: flex;
    align-items: center;
    color: var(--table-head);
    margin: 3px 0;
    svg {
      margin-left: 1rem;
      font-size: 1.25rem;
      color: var(--primary-shade-2);
    }
  }
`;
