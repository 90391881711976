import React, { useState } from "react";
import styled from "styled-components";
import { RiDragMove2Fill } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdViewQuilt } from "react-icons/md";
import {
  Avatar,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";

const TableComponent = ({
  data = [],
  metaData = [],
  viewFX,
  margin,
  check,
  marginBelow,
  isLoading,
  ...props
}) => {
  // set up pagination
  const [pageNumber, setPageNumber] = useState(0);

  const transPerPage = 10;
  const pageVisited = pageNumber * transPerPage;
  const pageCount = Math.ceil(data.length / transPerPage);
  const changePage = ({ selected }) => {
    window.scrollTo(0, 0);
    setPageNumber(selected);
  };

  return (
    <>
      <TableStyles marginBelow={marginBelow} margin={margin} {...props}>
        <Table variant="striped" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              {Array.isArray(metaData.cols) && metaData.cols.length
                ? metaData.cols.map((head, i) => <Th key={head + 1}>{head}</Th>)
                : null}
              <Th></Th>
            </Tr>
          </Thead>
          {data.length ? (
            <Tbody>
              {data
                ?.slice(pageVisited, pageVisited + transPerPage)
                .map((data, i) => {
                  return (
                    <Tr
                      style={{ cursor: "pointer" }}
                      onClick={viewFX ? () => viewFX(data?.id) : null}
                      key={i}
                    >
                      {metaData.keys.map((key, i) => {
                        switch (key) {
                          case "s/n":
                            return <Td>{i + 1}</Td>;
                          case "name":
                            return (
                              <Td key={data[key] + i}>
                                <div className="group">
                                  <Avatar
                                    src="https://bit.ly/sage-adebayo"
                                    size="sm"
                                    mr="2"
                                  />
                                  {data[key]}
                                </div>
                              </Td>
                            );

                          case "id":
                            return (
                              <Td key={data[key] + i} className={data[key]}>
                                #{data[key]}
                              </Td>
                            );

                          case "status":
                            return (
                              <Td key={data[key] + i} className={data[key]}>
                                <span></span> {data[key]}
                              </Td>
                            );

                          default:
                            return <Td key={data[key] + i}> {data[key]} </Td>;
                        }
                      })}
                      <Td>
                        {check ? (
                          <p className="group check">
                            Check <MdViewQuilt />
                          </p>
                        ) : (
                          <RiDragMove2Fill className="grab" />
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          ) : null}
        </Table>

        {data.length ? (
          <div className="paginating">
            <p>
              Showing {transPerPage * pageNumber + 1} -{" "}
              {transPerPage * pageCount < data.length
                ? transPerPage * pageCount
                : data.length}{" "}
              of {data.length}
            </p>
            <ReactPaginate
              previousLabel={<IoIosArrowBack />}
              nextLabel={<IoIosArrowForward />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationWrap"}
              previousLinkClassName={"prevBtn"}
              nextLinkClassName={"nextBtn"}
              disabledClassName={"disableBtn"}
              activeClassName={"activePage"}
            />
          </div>
        ) : (
          isLoading && (
            <Stack spacing="5" marginTop="5">
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
            </Stack>
          )
        )}
      </TableStyles>
    </>
  );
};

export default TableComponent;
const TableStyles = styled.div`
  margin-top: ${({ margin }) => (margin ? "2rem" : 0)};
  @media screen and (max-width: 789px) {
    overflow-y: scroll;
  }

  .css-rdeq8s tr:nth-of-type(odd) td {
    background: var(--white-color);
  }

  .css-rdeq8s tr:nth-of-type(odd) th,
  .css-rdeq8s tr:nth-of-type(odd) td {
    border-color: none;
  }

  thead {
    background: var(--white-color);
    border-bottom-width: ${({ marginBelow }) =>
      marginBelow ? marginBelow + "rem" : 0};

    th {
      color: var(--table-head);
    }
  }

  .grab {
    font-size: 1.5rem;
    color: var(--table-head);
    cursor: grabbing;
  }

  .group {
    display: flex;
    align-items: center;
  }

  .check {
    font-size: 0.85rem;
    color: var(--table-head);
    svg {
      color: var(--table-head);
      font-size: 1.3rem;
    }
  }

  /* Pagination Styles */

  .paginating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;

    .paginationWrap {
      width: 70%;
      height: auto;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .paginationWrap a {
      cursor: pointer;
      color: var(--dark-color);
      transition: all 0.5s ease-in-out;
      margin: 0 0.8rem;
    }

    .activePage a,
    .paginationWrap a:hover {
      color: var(--primary-color);
    }

    .disableBtn a {
      cursor: not-allowed;
    }
  }
`;
