import React from "react";
import { MdAdd } from "react-icons/md";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";
import TextInput from "../../components/Input/Input";
import DropDownMenu from "../../components/Menu/Menu";
import VerificationCard from "../../components/VerificationCard/VerificationCard";
import { employment, gender, title } from "../../lib/individualForm";
import TaxPayerFormStyles from "../../styles/TaxPayerForm";

const BusinessPayer = () => {
  return (
    <>
      <TaxPayerFormStyles inverse>
        <VerificationCard inverse label="CAC Registration Number" />
        <div className="section">
          <h2 className="title">Section 1: Organization Details</h2>
          <div className="long">
            <TextInput label="Organization Name" />
          </div>
          <div className="separator"></div>
          <div className="grid_group">
            <TextInput label="Nature of Business" />
            <TextInput label="Number of Employees" />
            <TextInput type="date" label="Date of Establishment" />
            <TextInput label="Contact Number" />
          </div>
          <div className="separator"></div>
          <div className="flex_group">
            <TextInput label="Contact Number" />
            <TextInput label="Email Address" />
          </div>
          <div className="separator"></div>
          <div className="half">
            <TextInput label="Organization Website" />
          </div>
        </div>
        <div className="section">
          <h2 className="title">Section 2: Head Office Address</h2>
          <div className="flex_group">
            <TextInput label="House Number" />
            <TextInput label="Estate/Street" />
          </div>
          <div className="separator"></div>
          <div className="grid_group">
            <TextInput label="State of Residence" />
            <TextInput label="LGA of Residence" />
            <TextInput label="Town of Residence" />
            <TextInput label="Zip/Postal Code" />
          </div>
          <div className="separator"></div>
          <div className="single_cta">
            <OutlinedBtn icon={<MdAdd />} text="Add Another" />
          </div>
        </div>

        <div className="section">
          <h2 className="title">Section 1: Director Information</h2>
          <div className="wrap">
            <TextInput label="National Identification Number (NIN)" />
            <FilledBtn text="Verify" inverse />
          </div>
          <div className="separator"></div>
          <div className="grid_group">
            <DropDownMenu label="Title" options={title} />
            <TextInput label="First Name" />
            <TextInput label="Middle Name" />
            <TextInput label="Last Name" />
            <TextInput label="Surname" />
            <DropDownMenu label="Gender" options={gender} />
            <TextInput type="date" label="Date Of Birth" />
            <TextInput label="Place of Birth" />
            <TextInput label="State of Origin" />
            <TextInput label="LGA" />
            <TextInput label="Nationality" />
            <TextInput label="Profession/Trade" />
            <DropDownMenu label="Employment Category" options={employment} />
          </div>
          <div className="separator"></div>

          <h2 className="title">Contact Information</h2>
          <div className="flex_group">
            <TextInput label="Contact Number" />
            <TextInput label="Email Address" />
          </div>
          <div className="separator"></div>

          <div className="single_cta">
            <OutlinedBtn icon={<MdAdd />} text="Add Another" />
          </div>
        </div>
        <div className="cta">
          <OutlinedBtn text="Back" />
          <FilledBtn inverse text="continue" />
        </div>
      </TaxPayerFormStyles>
    </>
  );
};

export default BusinessPayer;
