import { Input } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const TextInput = ({ label, ...props }) => {
  return (
    <>
      <InputStyles>
        <label>{label}</label>
        <Input {...props} />
      </InputStyles>
    </>
  );
};

export default TextInput;
const InputStyles = styled.div`
  label {
    display: block;
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 6px;
  }

  input {
    border-color: var(--primary-shade-2);
    background: var(--white-color);
    font-size: 0.85rem;
  }
`;
