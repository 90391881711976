import { Collapse, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";
import TextInput from "../../components/Input/Input";
import DropDownMenu from "../../components/Menu/Menu";
import VerificationCard from "../../components/VerificationCard/VerificationCard";
import { gender, title } from "../../lib/individualForm";
import TaxPayerFormStyles from "../../styles/TaxPayerForm";

const IndividualPayer = () => {
  const [employed, setEmployed] = useState(true);
  const [value, setValue] = useState("1");

  const handleSection3 = (e) => {
    setValue(e);
    e === "1" ? setEmployed(true) : setEmployed(false);
  };

  return (
    <>
      <TaxPayerFormStyles>
        <VerificationCard label="National Identification Number" />
        <div className="section">
          <h2 className="title">Section 1: Personal Details</h2>
          <div className="grid_group">
            <DropDownMenu label="Title" options={title} />
            <TextInput label="First Name" />
            <TextInput label="Middle Name" />
            <TextInput label="Last Name" />
            <TextInput label="Surname" />
            <DropDownMenu label="Gender" options={gender} />
            <TextInput label="Date Of Birth" type="date" />
            <TextInput label="Place of Birth" />
            <TextInput label="State of Origin" />
            <TextInput label="LGA" />
            <TextInput label="Nationality" />
            <TextInput label="Profession/Trade" />
          </div>
          <div className="separator"></div>

          <h2 className="title">Contact Information</h2>
          <div className="flex_group">
            <TextInput label="Contact Number" />
            <TextInput label="Email Address" />
          </div>
        </div>

        <div className="section">
          <h2 className="title">Section 2: Residential Address</h2>
          <div className="flex_group">
            <TextInput label="House Number" />
            <TextInput label="Estate/Street" />
          </div>
          <div className="separator"></div>
          <div className="grid_group">
            <TextInput label="State of Residence" />
            <TextInput label="LGA of Residence" />
            <TextInput label="Town of Residence" />
            <TextInput label="Zip/Postal Code" />
          </div>
        </div>

        <div className="section">
          <h2 className="title">Section 3: Employer Status</h2>
          <RadioGroup onChange={(e) => handleSection3(e)} value={value}>
            <Stack direction="row" spacing="16">
              <Radio value="1">Employed</Radio>
              <Radio value="2">UnEmployed</Radio>
              <Radio value="3">Retired</Radio>
            </Stack>
          </RadioGroup>
          <Collapse in={employed} animateOpacity>
            <div className="separator"></div>
            <div className="grid_group">
              <TextInput label="Title" />
              <TextInput label="First Name" />
              <TextInput label="Middle Name" />
              <TextInput label="Last Name" />
            </div>
            <div className="separator"></div>
            <div className="flex_group">
              <TextInput label="Contact Number" />
              <TextInput label="Email Address" />
            </div>
            <div className="separator"></div>
            <div className="grid_group">
              <TextInput label="State of Residence" />
              <TextInput label="LGA of Residence" />
              <TextInput label="Town of Residence" />
              <TextInput label="Zip/Postal Code" />
            </div>
          </Collapse>
        </div>

        <div className="cta">
          <OutlinedBtn text="Back" />
          <FilledBtn inverse text="continue" />
        </div>
      </TaxPayerFormStyles>
    </>
  );
};

export default IndividualPayer;
