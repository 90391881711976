import React, { useState } from "react";
import { SlideFade, useDisclosure } from "@chakra-ui/react";
import { HiOutlineSearch } from "react-icons/hi";
import styled from "styled-components";
import TextInput from "../../components/Input/Input";
import TableComponent from "../../components/Table/TableComponent";
import VerificationCard from "../../components/VerificationCard/VerificationCard";
import { payeTable } from "../../lib/payeTable";
import PopupModal from "../../components/PopupModal/PopupModal";
import VerifyNumber from "./VerifyNumber";

const BusinessVerification = () => {
  const [showSearch, setShowSearch] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const metaData = {
    cols: ["TIN", "Business Name", "date_of_establishment", "Contact Person"],
    keys: ["tin", "business_name", "date_of_establishment", "contact_person"],
  };

  return (
    <>
      <PopupModal isOpen={isOpen} onClose={onClose}>
        <VerifyNumber close={onClose} />
      </PopupModal>
      <BusinessVerificationStyles>
        <VerificationCard onClick={onOpen} inverse label="TIN or Reg. No" />
        <div className="table_title">
          <h3>List of Taxable Business</h3>
          <div className="flex justify_c">
            <SlideFade in={showSearch} offsetY="20px">
              <TextInput />
            </SlideFade>
            <HiOutlineSearch
              className="search_icon"
              onMouseOver={() => setShowSearch(true)}
            />
          </div>
        </div>
        <TableComponent
          onMouseEnter={() => setShowSearch(false)}
          metaData={metaData}
          data={payeTable}
          marginBelow={1}
        />
      </BusinessVerificationStyles>
    </>
  );
};

export default BusinessVerification;
const BusinessVerificationStyles = styled.div``;
