import React from "react";
import styled from "styled-components";
import images from "../../imgs/images";

const Banner = () => {
  return (
    <>
      <BannerStyles>
        <div className="user">
          <p className="emoji">😊</p>
          <div className="user_name">
            <p>Welcome Admin</p>
            <h2>Becky Taiwo </h2>
          </div>
        </div>
        <div className="svg">
          <img src={images.star} alt="star" />
        </div>
      </BannerStyles>
    </>
  );
};

export default Banner;
const BannerStyles = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primary-shade-1);
  padding: 1.2rem;
  background: var(--white-color);
  border-radius: 10px;
  margin-top: 1.5rem;

  .svg {
    img {
      width: 55px;
      height: 55px;
    }
  }

  .user {
    display: flex;
  }

  .emoji {
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  .user_name {
    color: var(--primary-shade-2);
    p {
      font-size: 0.95rem;
    }
  }
`;
