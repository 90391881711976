import React from "react";
import styled from "styled-components";
import Banner from "../../components/Banner/Banner";

const Home = () => {
  return (
    <>
      <HomeStyles>
        <Banner />
      </HomeStyles>
    </>
  );
};

export default Home;
const HomeStyles = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.7rem;
`;
