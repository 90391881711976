import React, { useState } from "react";
import styled from "styled-components";
import { MdViewQuilt } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import CompanyTag from "../../components/Tags/CompanyTag";
import TableComponent from "../../components/Table/TableComponent";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";
import CSVUploader from "../../components/CSVUploader/CSVUploader";
import InputManually from "./InputManually";
import { Collapse, useDisclosure } from "@chakra-ui/react";
const DeclareIncome = () => {
  const [showUploader] = useState(true);
  const { isOpen, onToggle } = useDisclosure();

  const metaData = {
    cols: [
      "S/N",
      "TIN",
      "Name of Employee",
      "Gross Income (N) Per Annum",
      "Taxable Income (N) Per Annum",
      "Pay E (N) Per Annum",
    ],
  };

  return (
    <>
      <DeclareIncomeStyles>
        <CompanyTag />
        <div className="actions">
          <OutlinedBtn inverse className="upload" text="Upload file" />
          <FilledBtn inverse text="Input Manually" clickFx={onToggle} />
        </div>
        <Collapse in={isOpen} animateOpacity>
          <InputManually />
        </Collapse>
        {!isOpen && (
          <div className="table_heading">
            <h3>
              <FaFileUpload /> Upload CSV File
            </h3>
            <h3>
              Check All <MdViewQuilt />
            </h3>
          </div>
        )}

        <div className="table_title">
          <h3>List of Added EMployee(s)</h3>
        </div>
        <TableComponent metaData={metaData} marginBelow={0} />
        {showUploader && <CSVUploader />}
      </DeclareIncomeStyles>
    </>
  );
};

export default DeclareIncome;
const DeclareIncomeStyles = styled.div`
  margin-bottom: 4rem;
  .actions {
    width: 350px;
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;

    .upload {
      button {
        border-color: var(--primary-color);
        color: var(--table-head);
      }
    }
  }

  .table_heading {
    h3 {
      cursor: pointer;
    }
  }

  .table_title {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;
