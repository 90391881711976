import React from "react";
import styled from "styled-components";
import { GiBackwardTime } from "react-icons/gi";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";

const VerifyNumber = ({close}) => {
  return (
    <>
      <VerifyNumberStyles>
        <div className="header">
          <div>
            <h5>Softpattern Technologies</h5>
            <p>Registered Business</p>
          </div>
          <div>
            <p>TIN: 392392023922</p>
            <p>Reg No: 823423924222</p>
          </div>
        </div>
        <div className="body">
          <div className="wrap">
            <div className="left">
              <Table variant="unstyled">
                <Tbody>
                  <Tr>
                    <Td>Date Established</Td>
                    <Td>2017-08-12</Td>
                  </Tr>
                  <Tr>
                    <Td>Number of Staff</Td>
                    <Td>No Record</Td>
                  </Tr>
                  <Tr>
                    <Td>Address</Td>
                    <Td>Tonga street, arab road, kubwa</Td>
                  </Tr>
                  <Tr>
                    <Td>Director</Td>
                    <Td>Mathew Adeboye</Td>
                  </Tr>
                  <Tr>
                    <Td>Phone Number</Td>
                    <Td>00920234023</Td>
                  </Tr>
                </Tbody>
              </Table>
            </div>
            <div className="right">
              <div className="group">
                <p>Last PayE payment</p>
                <p>No Record</p>
              </div>
              <p className="click">
                <GiBackwardTime />
                View PayE History
              </p>
            </div>
          </div>
          <div className="cta">
            <OutlinedBtn clickFx={close} inverse text="Back" />
            <FilledBtn clickFx={close} inverse text="Continue" />
          </div>
        </div>
        <div className="footer"></div>
      </VerifyNumberStyles>
    </>
  );
};

export default VerifyNumber;
const VerifyNumberStyles = styled.div`
  width: 100%;
  min-height: 430px;

  .header {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.2rem;
    padding-right: 4rem;

    h5 {
      color: var(--table-head);
      font-weight: bold;
    }

    p {
      font-size: 0.85rem;
    }
  }

  .body {
    width: 100%;
    height: 70%;
    padding: 1.3rem 1.3rem 2.2rem 2.2rem;
    background: var(--modal-bg);
    font-size: 0.87rem;
    .wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .left {
      width: 50%;
    }
    td {
      padding: 0;
      font-size: 0.85rem;
      padding-bottom: 1rem;
    }
    td:nth-child(2) {
      width: 55%;
    }
    td:nth-child(1) {
      color: var(--table-head);
      padding-right: 2rem;
      width: 45%;
    }

    .group {
      display: flex;
      align-items: center;
      font-size: 0.85rem;

      p:nth-child(1) {
        color: var(--table-head);
        padding-right: 1.5rem;
      }
    }

    .click {
      color: var(--table-head);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1rem;
      cursor: pointer;
      svg {
        margin-right: 5px;
        font-size: 1.5rem;
      }
    }
  }

  .cta {
    width: 350px;
    margin: 2rem auto 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer {
    width: 100%;
    height: 10%;
    padding: 2rem;
  }
`;
