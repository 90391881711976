import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import TopNav from "../../components/TopNav/TopNav";
import { taxPayer } from "../../lib/topNavData";

const TaskPayer = () => {
  const location = useLocation();

  let current = location.pathname.split("/")[3];

  return (
    <>
      {current ? (
        <TopNav
          title={taxPayer[current].title}
          sub={taxPayer[current].sub}
          background={taxPayer[current].background}
        />
      ) : (
        <Banner />
      )}
      <Outlet />
    </>
  );
};

export default TaskPayer;
