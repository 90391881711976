import axios from "axios";
import config from "./config";

const token = localStorage.getItem("irm_access");

const Axios = axios.create({
  baseURL: config.api_base_url,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default Axios;
