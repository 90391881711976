export const taxPayer = {
  individual: {
    title: "Individual TaxPayer Registration Form",
    sub: "Please fill in the information",
    background: "--primary-gradient-1",
  },
  business: {
    title: "Business TaxPayer Registration Form",
    sub: "Please fill in the information",
    background: "--secondary-gradient-1",
  },
};

export const taxManagement = {
  paye: {
    title: "Pay-As-You-Earn (PAYE)",
    sub: "Please fill in the information",
    background: "--dark-gradient-1",
  },
};
