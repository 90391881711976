import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    :root { 
        --primary-color: #44A842;
        --primary-shade-1: #2BA874;
        --primary-shade-2: #2AA875;
        --primary-gradient-1: transparent linear-gradient(180deg, #47A83E 0%, #2AA977 100%);
        --primary-gradient-2: transparent linear-gradient(180deg, #4BE63D 0%, #2AA977 100%);
        --secondary-gradient-1: transparent linear-gradient(180deg, #3E5FA8 0%, #2AA977 100%);
        --dark-gradient-1: transparent linear-gradient(142deg, #6F7D9B 0%, #191F1D 100%);
        --white-shade: #f4f6fA;
        --white-color: #FEFFFD;
        --off-white: rgba(255, 255, 255, 0.4); 
        --off-white-hover: rgba(255, 255, 255, 0.1); 
        --normal-card: #F6FBF6;
        --inverse-card: rgba(62, 95, 168, 0.06);
        --card_bg: rgba(60, 102, 163, 0.06);
        --text-color: #565656;
        --title-color: #8650AE;
        --table-head: #3C66A3;
        --modal-bg: #F5F7FA;
        --red-color: #FF0031;
        --yellow-color: #E8C100;

    }

    html, body {
        font-family: 'Montserrat', sans-serif;
        color: var(--white-color);
        scroll-behavior: smooth;
    }

    a {
        text-decoration: none;
    }
    
    input, button {
        outline: none !important;
    }

    .css-1c6j008:focus, .css-1c6j008[data-focus] {
        z-index: 1;
        border-color: var(--primary-color) !important;
        box-shadow: 0 0 0 1px var(--primary-color) !important;
    }
    
    button {
        cursor: pointer;
        transition: all 0.3s ease-in-out !important;
    }

    .css-9d6roe:focus, .css-9d6roe[data-focus] {
        border-color: var(--primary-color) !important;
        box-shadow: 0px 1px 0px 0px var(--primary-color) !important;
    }

    .css-1rggdzb[aria-checked=true], .css-1rggdzb[data-checked] {
        background: var(--secondary-gradient-1) !important;
        border-color: transparent !important;
        outline: none;
    }

    .css-1rggdzb:focus, .css-1rggdzb[data-focus] {
        box-shadow: none !important;
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .justify_b {
        justify-content: space-between;
    }

    .justify_c {
        justify-content: center;
    }

    .css-1yb2anq {
        z-index: 300000000000000 !important;
    }

    .css-qlig70 {
        padding: 0 !important;
    }

`;

export default GlobalStyles;
