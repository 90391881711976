import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";
import CompanyTag from "../../components/Tags/CompanyTag";

const GeneratePayBill = () => {
  return (
    <>
      <GeneratePayBillStyles>
        <CompanyTag />
        <div className="card">
          <h3 className="title">PayE Payment Schedule</h3>
          <div className="table_info">
            <h5 className="table_head">Pay-E-Year:</h5>
            <p>2021</p>
          </div>
          <div className="table_info">
            <h5>Ref:</h5>
            <p>Assess128129</p>
          </div>
          <div className="table">
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Duration(Month)</Th>
                  <Th>No. of Employee</Th>
                  <Th>Payable(N)</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>January</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>February</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>March</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>April</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>May</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>June</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>July</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>August</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>September</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>October</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>November</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
                <Tr>
                  <Td>December</Td>
                  <Td>12</Td>
                  <Td>34343.2332</Td>
                  <Td className="pending">Pending</Td>
                  <Td className="action">
                    <FilledBtn text="Generate Bill" />
                    <OutlinedBtn text="Re-Assess" />
                  </Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr className="footer">
                  <Th>Jan - Dec</Th>
                  <Th>12</Th>
                  <Th>100,000,00</Th>
                  <Th>-</Th>
                  <Td className="action">
                    <FilledBtn inverse text="Generate Bill" />
                    <OutlinedBtn
                      className="blue_btn"
                      inverse
                      text="Re-Assess"
                    />
                  </Td>
                </Tr>
              </Tfoot>
            </Table>
          </div>
        </div>
      </GeneratePayBillStyles>
    </>
  );
};

export default GeneratePayBill;
const GeneratePayBillStyles = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  .table_head {
    color: var(--table-head);
  }

  .card {
    width: 100%;
    margin-top: 2.5rem;
    min-height: 300px;
    background: var(--white-color);
    border: 2px solid #2ba873;
    padding: 2rem;
    padding-right: 0;
  }

  .title {
    text-align: center;
    color: var(--table-head);
    font-size: 0.87rem;
    font-weight: bold;
  }

  .table_info {
    display: flex;
    align-items: center;
    font-size: 0.88rem;

    p {
      margin-left: 10px;
    }
  }

  .table {
    width: 100%;
    margin-top: 2.2rem;

    .css-rdeq8s tr:nth-of-type(odd) td {
      background: var(--white-color);
    }

    .css-rdeq8s tr:nth-of-type(odd) th,
    .css-rdeq8s tr:nth-of-type(odd) td {
      border-color: none;
    }

    .footer {
      th {
        color: var(--red-color);
        font-size: 1rem;
      }

      .blue_btn button {
        border-color: var(--table-head);
        color: var(--table-head);

        &:hover {
          background: var(--table-head);
          color: var(--white-color);
        }
      }
    }

    thead {
      background: var(--white-color);

      th {
        color: var(--table-head);
      }
    }

    .pending {
      color: var(--yellow-color);
    }

    .action {
      display: flex;
      align-items: center;

      div:first-child {
        margin-right: 10px;
      }
    }
  }
`;
