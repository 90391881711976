import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import styled from "styled-components";

const DropDownMenu = ({ label, options }) => {
  const [value, setValue] = useState("");

  // eslint-disable-next-line
  useEffect(() => setValue(options[0]?.value), [options]);

  return (
    <>
      <DropDownMenuStyles>
        <div className="label">{label}</div>
        <Menu closeOnBlur>
          <MenuButton>
            {value} <AiOutlineCaretDown className="down" />
          </MenuButton>
          <MenuList>
            {options?.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => setValue(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </DropDownMenuStyles>
    </>
  );
};

export default DropDownMenu;
const DropDownMenuStyles = styled.div`
  .label {
    display: block;
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 6px;
  }

  button {
    width: 100%;
    span {
      width: 100%;
      height: 40px;
      border: 1.5px solid var(--primary-shade-2);
      background: var(--white-color);
      border-radius: 5px;
      font-size: 0.85rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;

      &:hover {
        background: none;
      }

      .down {
        color: var(--primary-color);
      }
    }
  }
`;
