import React from "react";
import styled from "styled-components";
import { FaFileUpload } from "react-icons/fa";

const CSVUploader = () => {
  return (
    <>
      <CSVUploaderStyles>
        <p>No Data yet</p>
        <h3>
          <FaFileUpload /> Upload CSV File
        </h3>
      </CSVUploaderStyles>
    </>
  );
};

export default CSVUploader;
const CSVUploaderStyles = styled.div`
  width: 100%;
  height: 350px;
  background: var(--card_bg);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3 {
    display: flex;
    align-items: center;
    color: var(--table-head);
    margin-top: 5px;
    cursor: pointer;

    svg {
        margin-right: 10px;
    }
  }

  p {
    font-size: 0.87rem;
  }
`;
