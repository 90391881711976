import React, { useState } from "react";
import styled from "styled-components";
import BusinessVerification from "../../parts/TaxManagement/BusinessVerification";
import DeclareIncome from "../../parts/TaxManagement/DeclareIncome";
import GeneratePayBill from "../../parts/TaxManagement/GeneratePayBill";
import TaxableIncome from "../../parts/TaxManagement/TaxableIncome";

const Paye = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "Business Verification",
    "Declare staff income",
    "Access Taxable Income",
    "Generate payable bill",
  ];

  const handleDisplay = () => {
    switch (activeTab) {
      case 0:
        return <BusinessVerification />;
      case 1:
        return <DeclareIncome />;
      case 2:
        return <TaxableIncome />;
      case 3:
        return <GeneratePayBill />;

      default:
        return <BusinessVerification />;
    }
  };

  return (
    <>
      <PayeStyles>
        <div className="second_nav">
          {tabs.map((tab, index) => (
            <p
              className={index === activeTab ? "active" : ""}
              onClick={() => setActiveTab(index)}
              key={tab + index}
            >
              {tab}
            </p>
          ))}
        </div>
        {handleDisplay()}
      </PayeStyles>
    </>
  );
};

export default Paye;
const PayeStyles = styled.div`
  width: 93%;

  .second_nav {
    width: 107.5%;
    position: sticky;
    top: 93px;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    background: var(--white-color);
    z-index: 150;

    p {
      margin-right: 2rem;
      font-size: 0.9rem;
      cursor: pointer;
      border-bottom: 2px solid var(--white-color);
      transition: all 0.3s ease-in-out;

      &.active {
        border-bottom: 2px solid var(--primary-color);
      }
    }
  }

  .table_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3rem 0 1rem;
    background-color: var(--white-color);
    padding: 1rem;
    color: var(--table-head);

    h3 {
      font-size: 0.85rem;
      font-weight: 600;
    }

    .search_icon {
      font-size: 2rem;
      margin-left: 2rem;
      cursor: pointer;
    }
  }

  .table_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
    color: var(--table-head);

    h3 {
      display: flex;
      align-items: flex-end;
      font-size: 0.89rem;

      svg {
        margin-left: 5px;
        font-size: 1.5rem;
        color: var(--primary-shade-2);
      }
    }
  }
`;
