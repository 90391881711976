import React, { useState } from "react";
import { Input, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilledBtn from "../../components/Button/Button";
import images from "../../imgs/images";
import axios from "axios";

const Login = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  
  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      username: id,
      password,
    };

    if (id && password) {
      axios
        .post(`${baseUrl}/user/api/v1/token/`, data)
        .then((res) => {
          localStorage.setItem("irm_refresh", res?.data?.refresh);
          localStorage.setItem("irm_access", res?.data?.access);
          setIsLoading(false);
          navigate("/dashboard/home");
        })
        .catch((err) => {
          setIsLoading(false);
          toast({
            title: "Ops",
            description: err?.response?.data?.detail,
            status: "error",
            isClosable: true,
          });
        });
    } else {
      setIsLoading(false);
      toast({
        title: "Field Required",
        description: "username or password cannot be empty",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <LoginStyles>
        <div className="left_side">
          <img src={images.login_svg} alt="svg" />
          <p>Trace, Track, Collect</p>
        </div>
        <div className="right_side">
          <div className="login_gr">
            <div className="colored_box">Admin Access Login</div>
            <form>
              <h3>Login With ID</h3>
              <Input
                variant="flushed"
                value={id}
                onChange={(e) => setId(e.target.value)}
                placeholder="Type ID Here"
              />
              <Input
                variant="flushed"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
              />
              <FilledBtn
                isLoading={isLoading}
                text="Access"
                className="btn"
                clickFx={handleSubmit}
              />
            </form>
            <p className="help">Need Help? Click</p>
          </div>
        </div>
      </LoginStyles>
    </>
  );
};

export default Login;
const LoginStyles = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left_side {
    width: 55%;
    height: 100%;
    background: var(--primary-gradient-1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 8%;

    img {
      width: 75%;
    }

    p {
      color: var(--white-shade);
      font-weight: bold;
      margin-top: 5rem;
    }
  }

  .right_side {
    position: absolute;
    background: var(--white-shade);
    width: 53%;
    height: 100%;
    right: 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login_gr {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      width: 100%;

      .colored_box {
        width: 220px;
        height: 120px;
        padding: 1.3rem;
        background: var(--primary-gradient-1);
        text-align: center;
        color: var(--white-shade);
        position: absolute;
        top: 6%;
        right: 25%;
        z-index: 2;
        padding-top: 2rem;
      }

      form {
        width: 300px;
        background: var(--white-color);
        padding: 3.2rem 1.2rem;
        z-index: 3;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        margin-top: 6rem;
        margin-bottom: 1.3rem;

        h3 {
          color: var(--primary-color);
        }

        input {
          margin-top: 1.5rem;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1.5rem;
        }
      }

      .help {
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }
`;
