export const title = [
  {
    label: "Master",
    value: "master",
  },
  {
    label: "Mrs",
    value: "mrs",
  },
  {
    label: "Mr.",
    value: "mr",
  },
  {
    label: "Miss",
    value: "miss",
  },
  {
    label: "Doctor",
    value: "doctor",
  },
  {
    label: "Engr",
    value: "Engineer",
  },
];

export const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const employment = [
  {
    label: "Employed",
    value: "employed",
  },
  {
    label: "Unemployed",
    value: "unemployed",
  },
  {
    label: "Self Employed",
    value: "self_employed",
  },
  {
    label: "Student",
    value: "student",
  },
];
