import { Button } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const FilledBtn = ({ text, className, clickFx, inverse, icon, isLoading }) => {
  return (
    <>
      <ButtonStyles className={className} inverse={inverse}>
        <Button isLoading={isLoading} leftIcon={icon} onClick={clickFx}>
          {text}
        </Button>
      </ButtonStyles>
    </>
  );
};

export const OutlinedBtn = ({ text, className, clickFx, inverse, icon, isLoading }) => {
  return (
    <OutlineStyle className={className} inverse={inverse}>
      <Button isLoading={isLoading} variant="outline" onClick={clickFx} leftIcon={icon}>
        {text}
      </Button>
    </OutlineStyle>
  );
};

export default FilledBtn;
const ButtonStyles = styled.div`
  button {
    min-width: 160px;
    background: ${({ inverse }) =>
      inverse ? "var(--secondary-gradient-1)" : "var(--primary-gradient-1)"};
    color: var(--white-color);

    &:hover {
      background: ${({ inverse }) =>
        inverse ? "var(--primary-gradient-1)" : "var(--primary-gradient-2)"};
    }
  }
`;

const OutlineStyle = styled.div`
  button {
    min-width: 160px;
    color: var(--primary-color);
    border: 1.5px solid var(--primary-color);

    &:hover {
      background: ${({ inverse }) =>
        inverse ? "var(--primary-gradient-1)" : "var(--primary-gradient-2)"};
      color: var(--white-shade);
    }
  }
`;
