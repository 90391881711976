import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import AllLinks from "../../lib/navlinks";
import images from "../../imgs/images";
import { Collapse, useDisclosure } from "@chakra-ui/react";

const Sidebar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [clicked, setClicked] = useState("");

  const handleDrop = (label) => {
    setClicked(label);
    onToggle();
    console.log(clicked);
  };

  const customWidth = () => {
    switch (clicked) {
      case "TaxPayer Registration":
        return "68%";
      case "Tax Management":
        return "82.5%";
      default:
        return "68%";
    }
  };

  return (
    <>
      <SidebarStyles customWidth={customWidth()} showBefore={clicked}>
        <div className="logo">
          <img src={images.logo} alt="Logo" />
        </div>
        <div className="top_level">
          <NavLink to="/dashboard/home">Home</NavLink>
          <NavLink to="/notification">Notification</NavLink>
        </div>
        <div className="main_nav">
          {AllLinks.map((link, index) => (
            <div
              key={link.label + index}
              className={
                clicked === link.label && isOpen ? "links sudo" : "links"
              }
            >
              <p
                onClick={() => handleDrop(link.label)}
                className={
                  clicked === link.label && isOpen
                    ? "main_link sudo"
                    : "main_link"
                }
              >
                {link.label}
                {clicked === link.label && isOpen ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowForward />
                )}
              </p>
              <Collapse
                in={clicked === link.label ? isOpen : null}
                animateOpacity
              >
                <div className="sub_links">
                  {link?.subLinks?.map((sub, i) => (
                    <NavLink key={sub.route + i} end to={sub.route}>
                      {sub.label}
                    </NavLink>
                  ))}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </SidebarStyles>
    </>
  );
};

export default Sidebar;
const SidebarStyles = styled.div`
  width: 20%;
  height: 100%;
  background: var(--primary-gradient-1);
  position: fixed;
  top: 0;
  left: 0;
  color: var(--white-color);
  font-size: 1.05rem;
  overflow-y: scroll;

  a {
    display: block;
    padding: 0.15rem 0.8rem;
    margin-top: 0.6rem;
    transition: background 0.3s ease-in-out;

    &.active {
      &:hover {
        background: var(--off-white);
      }
      background: var(--off-white);
    }

    &:hover {
      background: var(--off-white-hover);
    }
  }

  .logo {
    padding: 0.15rem 0.8rem;
    margin-top: 1.5rem;
  }

  .top_level {
    margin: 2rem 0;
  }

  .main_nav {
    width: 100%;
    margin-top: 1.5rem;

    .links {
      padding: 1rem;
      padding-left: 0.8rem;
      border: 1px solid var(--off-white);
      border-left: 0;
      border-right: 0;
      position: relative;

      &.sudo {
        &::before {
          content: "";
          width: 2px;
          height: ${({ customWidth }) => customWidth};
          background: var(--off-white);
          position: absolute;
          left: 4px;
          bottom: 27px;
        }
      }
    }
  }

  .main_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    &.sudo {
      &::before {
        content: "";
        width: 10px;
        height: 2px;
        background: var(--off-white);
        position: absolute;
        left: -7px;
      }
    }
  }

  .sub_links {
    margin-left: 1rem;

    a {
      font-size: 0.95rem;
      padding-left: 5px;
      position: relative;

      &::before {
        content: "";
        width: 20px;
        height: 2px;
        background: var(--off-white);
        position: absolute;
        left: -22px;
        top: 50%;
      }
    }
  }
`;
