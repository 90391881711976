import React from "react";
import styled from "styled-components";
import { MdViewQuilt } from "react-icons/md";
import CompanyTag from "../../components/Tags/CompanyTag";
import TableComponent from "../../components/Table/TableComponent";
import { taxableIncomeData } from "../../lib/payeTable";
import FilledBtn, { OutlinedBtn } from "../../components/Button/Button";

const TaxableIncome = () => {
  const metaData = {
    cols: [
      "S/N",
      "TIN",
      "Name of Employee",
      "Gross Income (N) Per Annum",
      "Taxable Income (N) Per Annum",
      "Pay E (N) Per Annum",
    ],
    keys: [
      "s/n",
      "tin",
      "employee_name",
      "gross_income",
      "taxable_income",
      "pay_e",
    ],
  };
  return (
    <>
      <TaxableIncomeStyles>
        <CompanyTag />
        <div className="table_heading">
          <h3>List of Added Employee(s)</h3>
          <h3>
            Check All <MdViewQuilt />
          </h3>
        </div>
        <TableComponent
          metaData={metaData}
          data={taxableIncomeData}
          check
          numbered
        />
        <div className="total">
          <h3>Total Employee PayE</h3>
          <h3>N100,000,000</h3>
        </div>
        <div className="cta">
          <OutlinedBtn text="Back" />
          <FilledBtn text="Generate Bill" inverse />
        </div>
      </TaxableIncomeStyles>
    </>
  );
};

export default TaxableIncome;
const TaxableIncomeStyles = styled.div`
  width: 100%;
  padding-bottom: 4rem;

  .total {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1.5rem 0;
    color: var(--red-color);

    h3:nth-child(2) {
      border-radius: 50%;
      border: 2px solid var(--primary-shade-2);
      margin-left: 1rem;
      padding: 12px;
    }
  }

  .cta {
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: right;
  }
`;
