import styled from "styled-components";

const TaxPayerFormStyles = styled.div`
  .wrap {
    width: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    button {
      margin-left: 1rem;
    }
  }

  .title {
    color: var(--title-color);
    margin-bottom: 1.2rem;
    font-weight: bold;
  }

  .half {
    width: 70%;
  }

  .long {
    width: 100%;
  }

  .single_cta {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .section {
    margin: 2rem 0;
    width: 70%;
    background: ${({ inverse }) =>
      inverse ? "var(--inverse-card)" : "var(--normal-card)"};
    padding: 1.5rem;

    .grid_group {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
      grid-gap: 1.5rem;
      grid-auto-flow: dense;
      column-count: 2;

      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      }

      @media screen and (max-width: 789px) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
      }
    }

    .separator {
      margin-top: 1.5rem;
    }

    .flex_group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:nth-child(1) {
        width: 30%;
      }
      div:nth-child(2) {
        width: 65%;
      }
    }
  }

  .cta {
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
  }
`;

export default TaxPayerFormStyles;
