import React, { useState } from "react";
import styled from "styled-components";
import { useToast } from "@chakra-ui/react";
import FilledBtn from "../Button/Button";
import TextInput from "../Input/Input";

const VerificationCard = ({ inverse, label, onClick }) => {
  const [code, setCode] = useState("");
  const toast = useToast();

  const handleVerify = () => {
    if (code) {
      onClick();
    } else {
      toast({
        title: "Empty Field",
        description: "Sorry! Field cannot be empty",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VerificationCardStyles inverse={inverse}>
        <div className="wrap">
          <TextInput
            value={code}
            onChange={(e) => setCode(e.target.value)}
            label={label}
          />
          <FilledBtn text="Verify" clickFx={handleVerify} inverse />
        </div>
      </VerificationCardStyles>
    </>
  );
};

export default VerificationCard;
const VerificationCardStyles = styled.div`
  margin-top: 2rem;
  width: 70%;
  padding: 1.5rem;
  background: ${({ inverse }) =>
    inverse ? "var(--inverse-card)" : "var(--normal-card)"};

  .wrap {
    width: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    button {
      margin-left: 1rem;
    }
  }
`;
